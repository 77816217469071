import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import axios from "../helpers/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress } from "@mui/material";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/layouts/mobile.css";
import gregorian_es_locale from "../helpers/gregorian_es_locale";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Vacaciones = () => {
  const navigate = useNavigate();

  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [dates, setDates] = useState("");

  const datePickerRef = useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSubmit = async () => {
    // aqui se va a guardar

    let fechas = await document.getElementById("calendarioInput").value;
    fechas = await document.getElementById("calendarioInput").value;
    setOpenBackdrop(true);
    datePickerRef.current.openCalendar();

    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenUsuario"),
      },
    };

    await axios
      .post(
        "fichaje/marcar/vacaciones",
        {
          data: fechas,
          permiso_id: 0,
        },
        headers
      )
      .then(async (response) => {
        return navigate("/?message_success=Vacaciones solicitadas correctamente");
      })
      .catch((error) => {
        try {
          if (error.response.data.message === undefined || error.response.data.message === "") {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });
  };

  useEffect(() => {
    const init = () => {
      const token = localStorage.getItem("tokenUsuario");

      if (token === undefined || token === "" || token === null) {
        return navigate("/login");
      }

      datePickerRef.current.openCalendar();
    };

    init();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
      marginX={4}
    >
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DatePicker
        id="calendarioInput"
        ref={datePickerRef}
        locale={gregorian_es_locale}
        weekStartDayIndex={1}
        className="rmdp-mobile"
        multiple
        value={dates}
        onChange={setDates}
        plugins={[<DatePanel />]}
        calendarPosition="bottom-right"
        format="DD/MM/YYYY"
        onClose={() => handleSubmit()}
        mobileLabels={{
          OK: "Guardar",
          CANCEL: "\n",
        }}
        mobileButtons={[
          {
            label: "Volver",
            type: "button",
            className: "rmdp-button rmdp-action-button",
            onClick: () => navigate("/"),
          },
        ]}
      />

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Vacaciones;
