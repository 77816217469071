// archivo axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://master.versatils.dev/api/',
  timeout: 8000
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

export default instance;