import React, { useEffect } from 'react';

const OrientationLockWrapper = ({ children }) => {
    useEffect(() => {
        const lockOrientation = () => {

            if (window.screen.orientation && window.screen.orientation.lock) {

                const defaultOrientation = 'portrait';

                // Verificar si la orientación actual es diferente a la orientación por defecto
                if (window.screen.orientation.type !== defaultOrientation) {
                    window.screen.orientation.lock(defaultOrientation).catch((error) => {
                        console.error('No se pudo bloquear la orientación del dispositivo:', error);
                    });
                }
            }

        };

        lockOrientation();

        return () => {
            if (window.screen.orientation && window.screen.orientation.unlock) {
                window.screen.orientation.unlock();
            }
        };
    }, []);

    return <>{children}</>;
};

export default OrientationLockWrapper;
