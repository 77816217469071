import React, { useContext, useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { EmpresaContext } from "../context/EmpresaProvider";
import { useNavigate } from "react-router-dom";
import axios from "../helpers/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginEmpresa = () => {
  const navigate = useNavigate();

  const { setCodigoEmpresa, codigoEmpresa, setLogoEmpresa, setNombreEmpresa } =
    useContext(EmpresaContext);
  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageError, setMessageError] = useState("");

  const handleSubmit = () => {
    setOpenBackdrop(true);
    axios
      .get("fichaje/login/empresa/" + codigoEmpresa)
      .then((response) => {
        setLogoEmpresa(response.data.url);
        setNombreEmpresa(response.data.nombre);
        localStorage.setItem("codigoEmpresa", codigoEmpresa);
        localStorage.setItem("logoEmpresa", response.data.url);
        localStorage.setItem("nombreEmpresa", response.data.nombre);
        return navigate("/login/usuario");
      })
      .catch((error) => {
        try {
          if (error.response.data.message === undefined) {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          console.log(error.message);
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenUsuario");

    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });

    if (token !== undefined && token !== "" && token !== null) {
      return navigate("/");
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginX={4}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <img
        src="https://versatils.net/wp-content/uploads/2021/09/cropped-Design-sem-nome-11.png"
        alt=""
        width={80}
        style={{ marginTop: "15%" }}
      />
      <TextField
        label="Código de empresa"
        id="codigoEmpresa"
        variant="outlined"
        value={codigoEmpresa ? codigoEmpresa : ""}
        onChange={(event) => setCodigoEmpresa(event.target.value)}
        type="number"
        fullWidth
        sx={{ marginTop: 2, maxWidth: 500 }}
      />
      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSubmit}
      >
        Enviar
      </Button>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginEmpresa;
