import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { EmpresaContext } from "../context/EmpresaProvider";
import { useNavigate } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import axios from "../helpers/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginUsuario = () => {
  const navigate = useNavigate();

  const {
    codigoEmpresa,
    logoEmpresa,
    nombreEmpresa,
    setNombreUsuario,
    setImagenUsuario,
    setTokenUsuario,
    setCodigoEmpresa,
    setNombreEmpresa,
    setLogoEmpresa,
  } = useContext(EmpresaContext);

  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [codigoUsuario, setCodigoUsuario] = useState();
  const [pinUsuario, setPinUsuario] = useState();

  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: "auto",
    left: "auto",
    position: "fixed",
  };

  const handleSubmit = () => {
    setOpenBackdrop(true);
    axios
      .post("/fichaje/login/usuario", {
        codigo_empresa: codigoEmpresa,
        codigo_usuario: codigoUsuario,
        pin_usuario: pinUsuario,
      })
      .then((response) => {
        setImagenUsuario(response.data.imagen_usuario);
        setNombreUsuario(response.data.nombre_usuario);
        setTokenUsuario(response.data.token);

        localStorage.setItem("nombreUsuario", response.data.nombre_usuario);
        localStorage.setItem("imagenUsuario", response.data.imagen_usuario);
        localStorage.setItem("tokenUsuario", response.data.token);

        return navigate("/");
      })
      .catch((error) => {
        try {
          if (
            error.response.data.message === undefined ||
            error.response.data.message === ""
          ) {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleQuit = () => {
    setCodigoEmpresa();
    setNombreEmpresa();
    setLogoEmpresa();

    localStorage.removeItem("codigoEmpresa");
    localStorage.removeItem("nombreEmpresa");
    localStorage.removeItem("logoEmpresa");

    return navigate("/login");
  };

  useEffect(() => {
    const codigo = localStorage.getItem("codigoEmpresa");

    if (codigo === undefined || codigo === "" || codigo === null) {
      return navigate("/login");
    }

    const token = localStorage.getItem("tokenUsuario");

    if (token !== undefined && token !== "" && token !== null) {
      return navigate("/");
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginX={4}
      marginY={4}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ "& > :not(style)": { m: 1 } }} onClick={handleQuit}>
        <Fab color="error" aria-label="add" style={style}>
          <CloseIcon />
        </Fab>
      </Box>
      <Typography mb={4} mt={2} variant="h4">
        {nombreEmpresa}
      </Typography>
      <Avatar
        alt=""
        src={logoEmpresa}
        sx={{ width: 150, height: 150, marginTop: '3%' }}
      />
      <TextField
        label="Código de usuario"
        id="codigoUsuario"
        variant="outlined"
        onChange={(event) => setCodigoUsuario(event.target.value)}
        type="number"
        fullWidth
        sx={{ marginTop: 4, maxWidth: 500 }}
      />
      <TextField
        label="PIN de usuario"
        id="pinUsuario"
        variant="outlined"
        onChange={(event) => setPinUsuario(event.target.value)}
        type="number"
        fullWidth
        sx={{ marginTop: 4, maxWidth: 500 }}
      />
      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSubmit}
      >
        Enviar
      </Button>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginUsuario;
