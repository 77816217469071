import React, { useContext, useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { EmpresaContext } from "../context/EmpresaProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Fab from "@mui/material/Fab";
import DialogContentText from "@mui/material/DialogContentText";
import { Cron } from "../helpers/cron";
import { Wifi, WifiOff } from "@mui/icons-material";
import Badge from '@mui/material/Badge';
import axios from "../helpers/axios";
import Chip from '@mui/material/Chip';
import { db } from "../helpers/db";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HomeUsuario = () => {
  const navigate = useNavigate();

  const { imagenUsuario, setTokenUsuario, setImagenUsuario, setNombreUsuario, nombreUsuario } =
    useContext(EmpresaContext);
  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [severityMessage, setSeverityMessage] = useState("error");
  const [entradaMessage, setEntradaMessage] = useState("");
  const [totalNotificaciones, setTotalNotificaciones] = useState(0);
  const [searchParams] = useSearchParams();
  const [versionApp] = useState(1.24);
  const [versionAppServer, setVersionAppServer] = useState(0.0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleCloseDialog = (e) => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    setOpenDialog(true);
  };

  const handleFichaje = async () => {
    try {
      const position = await new Promise((resolve, reject) =>
        window.navigator.geolocation.getCurrentPosition(resolve, reject)
      );
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      navigate("/fichaje/" + latitude + "/" + longitude);
    } catch (error) {
      setOpenError(true);
      setMessageError("No pudimos obtener tu localizacion");
      setOpenDialog(false);
    }
  };

  const handleSalir = () => {
    setTokenUsuario();
    setImagenUsuario();
    setNombreUsuario();

    localStorage.removeItem("nombreUsuario");
    localStorage.removeItem("imagenUsuario");
    localStorage.removeItem("tokenUsuario");

    return navigate("/login/usuario");
  };

  const handleSolicitarVacaciones = () => {
    return navigate("/vacaciones");
  };

  const handleSolicitarPermisos = () => {
    return navigate("/permisos");
  };
  
  const handleNotificaciones = () => {
    return navigate("/notificaciones");
  }

  const handleTotalNotificaciones = async () => {

    let token = localStorage.getItem("tokenUsuario");

    if (!token) {
      return;
    }

    if (!window.navigator.onLine) {
      return;
    }

    const headers = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios
      .get("/fichaje/notificaciones/pendientes", headers)
      .then((response) => {
        setTotalNotificaciones(response.data.cantidad ?? 0);
        setVersionAppServer(response.data.version_app ?? 0.0)
        return
      })
      .catch((error) => {
        try {
          if (
            error.response.data.message === undefined ||
            error.response.data.message === ""
          ) {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });

    setOpenBackdrop(false);
  };

  const handleVersion = async () => {
    await db.table("fichajes").clear();
    localStorage.setItem("entrada", "entrada");
    window.location.reload();
  }

  useEffect(() => {
    const token = localStorage.getItem("tokenUsuario");

    if (token === undefined || token === "" || token === null) {
      return navigate("/login");
    }

    const entrada = localStorage.getItem("entrada");
    if (!entrada) {
      localStorage.setItem("entrada", "entrada");
      setEntradaMessage("entrada");
    } else {
      setEntradaMessage(entrada);
    }

    const success = searchParams.get("message_success");
    searchParams.delete("message_success");

    const warning = searchParams.get("message_warning");
    searchParams.delete("message_warning");

    if (success) {
      setSeverityMessage("success");
      setOpenError(true);
      setMessageError(success);
    }else if(warning){
      setSeverityMessage("warning");
      setOpenError(true);
      setMessageError(warning);
    }
  }, []);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let result = await Cron();
      if (
        typeof result === typeof "" &&
        ["entrada", "salida"].includes(result)
      ) {
        setEntradaMessage(result);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleTotalNotificaciones();
  }, [])

  const style = {
    margin: 10,
    top: "auto",
    right: 20,
    bottom: "auto",
    left: "auto",
    position: "fixed",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginX={4}
      //   style={{ minHeight: "100vh" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Fab
          color={isOnline ? "success" : "error"}
          aria-label="add"
          style={style}
        >
          {isOnline ? <Wifi /> : <WifiOff />}
        </Fab>
      </Box>
      {/* <img
        src={imagenUsuario}
        alt=""
        // width={100}
        variant="circular"
        style={{ marginTop: "15%", objectFit: "contain", width: '200px', height: '200px', borderRadius: 200/2}}
      /> */}
      <Avatar
      alt=""
        src={imagenUsuario}
        sx={{ width: 150, height: 150, marginTop: '10%' }}
      />

      <Chip label={nombreUsuario} sx={{ marginTop: '5%' }}/>

      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSubmit}
      >
        {entradaMessage}
      </Button>

      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSolicitarPermisos}
      >
        Solicitar Permisos
      </Button>

      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSolicitarVacaciones}
      >
        Solicitar Vacaciones
      </Button>

      <Button
        variant="contained"
        color="primary"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleNotificaciones}
      >
        Notificaciones
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {totalNotificaciones > 0 ?
          <Badge badgeContent={totalNotificaciones} color="error">
          </Badge> :
          ""
        }
        
      </Button>

      <Button
        variant="outlined"
        color="error"
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSalir}
      >
        Salir
      </Button>

        {versionAppServer > versionApp ? 
          <Chip color="error" onClick={handleVersion} label={`Version: ${versionApp}`} sx={{ marginTop: '2%', marginBottom: '1%' }}/>
          : <Chip onClick={handleVersion} label={`Version: ${versionApp}`} sx={{ marginTop: '2%', marginBottom: '1%' }}/>
        }

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severityMessage}
          sx={{ width: "100%" }}
        >
          {messageError}
        </Alert>
      </Snackbar>

      {/* Alert dialog to confirm */}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ventana de confirmación"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres hacer un fichaje?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No</Button>
          <Button onClick={handleFichaje} autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HomeUsuario;
