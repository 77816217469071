import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import axios from "../helpers/axios";

import { CheckCircle, Block, HourglassBottom } from "@mui/icons-material";

const Alert2 = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notificaciones = () => {
  const navigate = useNavigate();

  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [messageError, setMessageError] = useState("");
  const [notificaciones, setNotificaciones] = useState([]);

  const errorAx = (error) => {
    try {
      if (
        error.response.data.message === undefined ||
        error.response.data.message === ""
      ) {
        throw Error("");
      }
      setMessageError(error.response.data.message);
    } catch (catcherror) {
      setMessageError(error.message);
    } finally {
      setOpenBackdrop(false);
      setOpenError(true);
    }
  };

  const handleClose = () => {
    return navigate('/');
  };

  const handleNotificaciones = async () => {
    setOpenBackdrop(true);
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenUsuario"),
      },
    };

    await axios
      .get("/fichaje/notificaciones", headers)
      .then((response) => {
        console.log(response.data);
        setNotificaciones(response.data);
      })
      .catch((error) => {
        errorAx(error);
      });

    setOpenBackdrop(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenUsuario");

    if (token === undefined || token === "" || token === null) {
      return navigate("/login");
    }

    setOpenBackdrop(false);

    handleNotificaciones();
  }, []);

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Notificaciones
          </Typography>
          <Button color="inherit" onClick={handleClose}>Volver</Button>
        </Toolbar>
      </AppBar>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "30vh" }}
        sx={{ width: "100%" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {notificaciones.length > 0 ? (
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {notificaciones.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText
                        primary={item.texto}
                        secondary={item.fecha}
                      />
                      <ListItemIcon>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                        {item.estado === "Pendiente" ? <HourglassBottom color="primary"/>
                            : item.estado === "Rechazado" ? <Block color="error"/>
                            : <CheckCircle color="success"/>                             
                        }
                          <ListItemText primary={item.estado}/>
                        </div>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        ) : (
          "Aún no tienes notificaciones"
        )}

        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert2 onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {messageError}
          </Alert2>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Notificaciones;
