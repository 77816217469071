import React, { createContext, useState, useEffect, useLayoutEffect } from "react";

export const EmpresaContext = createContext()

const EmpresaProvider = (props) => {

    const [codigoEmpresa, setCodigoEmpresa] = useState();
    const [nombreEmpresa, setNombreEmpresa] = useState("");
    const [logoEmpresa, setLogoEmpresa] = useState("");
    const [tokenUsuario, setTokenUsuario] = useState("");
    const [imagenUsuario, setImagenUsuario] = useState();
    const [nombreUsuario, setNombreUsuario] = useState();

    useEffect(() => {
        //get data from localstorage
        const codigoEmpresa1 = localStorage.getItem('codigoEmpresa');
        const nombreEmpresa1 = localStorage.getItem('nombreEmpresa');
        const logoEmpresa1 = localStorage.getItem('logoEmpresa');
        const tokenUsuario1 = localStorage.getItem('tokenUsuario');
        const imagenUsuario1 = localStorage.getItem('imagenUsuario');
        const nombreUsuario1 = localStorage.getItem('nombreUsuario');
        // set data in the context
        setCodigoEmpresa(codigoEmpresa1);
        setNombreEmpresa(nombreEmpresa1);
        setLogoEmpresa(logoEmpresa1);
        setTokenUsuario(tokenUsuario1);
        setImagenUsuario(imagenUsuario1);
        setNombreUsuario(nombreUsuario1);

        // console.log(tokenUsuario + "estamos en provider");
    }, [])
    

    return (
        <EmpresaContext.Provider value={{
            codigoEmpresa, setCodigoEmpresa, 
            nombreEmpresa, setNombreEmpresa,
            logoEmpresa, setLogoEmpresa,
            tokenUsuario, setTokenUsuario,
            imagenUsuario, setImagenUsuario,
            nombreUsuario, setNombreUsuario
        }}>
            {props.children}
        </EmpresaContext.Provider>
    )
}

export default EmpresaProvider;