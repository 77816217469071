import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import axios from "../helpers/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/layouts/mobile.css";
import gregorian_es_locale from "../helpers/gregorian_es_locale";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Permisos = () => {
  const navigate = useNavigate();

  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [permisos, setPermisos] = useState([]);
  const [selectedPermiso, setSelectedPermiso] = useState("");
  const [maxDatePicker, setMaxDatePicker] = useState(new Date(2030, 1, 1));

  const [dates, setDates] = useState("");

  const datePickerRef = useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSubmit = async () => {
    // aqui se va a guardar
    
    let fechas = await document.getElementById("calendarioInput").value;
    fechas = await document.getElementById("calendarioInput").value;

    if (fechas === undefined || fechas === "") {
      setMessageError("Selecciona al menos una fecha");
      setOpenError(true);
      datePickerRef.current.openCalendar();
      return;
    }

    let arrayFechas = fechas.split(",");

    let permiso = permisos.find((permiso) => permiso.id === selectedPermiso);

    if (permiso === undefined) {
      setMessageError("Selecciona un tipo de permiso");
      setOpenError(true);
      return;
    }

    if (arrayFechas.length > permiso.dias_disponibles) {
      setMessageError("Puedes seleccionar máximo "+ permiso.dias_disponibles +" días");
      setOpenError(true);
      datePickerRef.current.openCalendar();
      return;
    }

    datePickerRef.current.openCalendar();
    setOpenBackdrop(true);

    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenUsuario"),
      },
    };

    await axios
      .post(
        "fichaje/marcar/vacaciones",
        {
          data: fechas,
          permiso_id: selectedPermiso
        },
        headers
      )
      .then(async (response) => {
        return navigate("/?message_success=Permiso solicitado correctamente");
      })
      .catch((error) => {
        try {
          if (error.response.data.message === undefined || error.response.data.message === "") {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });
  };

  const handleSelectPermisos = async () => {
    let token = localStorage.getItem("tokenUsuario");

    if (!token) {
      return;
    }

    if (!window.navigator.onLine) {
      return;
    }

    const headers = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios
      .get("/fichaje/permisos", headers)
      .then((response) => {
        setPermisos(response.data);
        return;
      })
      .catch((error) => {
        try {
          if (error.response.data.message === undefined || error.response.data.message === "") {
            throw Error("");
          }
          setMessageError(error.response.data.message);
        } catch (catcherror) {
          setMessageError(error.message);
        } finally {
          setOpenBackdrop(false);
          setOpenError(true);
        }
      });

    setOpenBackdrop(false);
  };

  const handleSelectPermisoChange = (event) => {
    setSelectedPermiso(event.target.value);
    
    if (event.target.value === "") {
      return;
    }

    let permiso = permisos.find((permiso) => permiso.id === event.target.value);

    if (permiso === undefined) {
      return;
    }

    let limitDate = new Date(permiso.fecha_limite);
    limitDate.setDate(limitDate.getDate() + 1);
    setMaxDatePicker(limitDate);
    datePickerRef.current.openCalendar();

  }

  useEffect(() => {
    handleSelectPermisos();
  }, []);

  useEffect(() => {
    const init = () => {
      const token = localStorage.getItem("tokenUsuario");

      if (token === undefined || token === "" || token === null) {
        return navigate("/login");
      }

      // datePickerRef.current.openCalendar();
    };

    init();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
      marginX={4}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="selectOptions-label">Selecciona una opción de permiso</InputLabel>
            <Select
              labelId="selectOptions-label"
              id="selectOptions"
              value={selectedPermiso}
              onChange={handleSelectPermisoChange}
            >
              <MenuItem value="">
                <em>Seleccione...</em>
              </MenuItem>
              {permisos.map((permiso, index) => (
                <MenuItem key={index} value={permiso.id}>
                  {permiso.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DatePicker
        id="calendarioInput"
        ref={datePickerRef}
        locale={gregorian_es_locale}
        weekStartDayIndex={1}
        className="rmdp-mobile"
        multiple
        value={dates}
        onChange={setDates}
        plugins={[<DatePanel />]}
        maxDate={maxDatePicker}
        calendarPosition="bottom-right"
        format="DD/MM/YYYY"
        onClose={() => handleSubmit()}
        mobileLabels={{
          OK: "Guardar",
          CANCEL: "\n",
        }}
        mobileButtons={[
          {
            label: "Volver",
            type: "button",
            className: "rmdp-button rmdp-action-button",
            onClick: () => navigate("/"),
          },
        ]}
      />

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Permisos;
