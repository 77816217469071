import { db } from "../helpers/db";
import { DateTime, Interval } from "luxon";
import axios from "../helpers/axios";

export const Cron = async () => {
  //verificar si hay un fichaje del dia anterior
  let ultimo = await db
    .table("fichajes")
    .orderBy("id")
    .reverse()
    .limit(1)
    .first();

  if (ultimo && ultimo.fecha_sal === null) {
    //obtenemos fecha actual y restamos un día
    let dt = DateTime.now().toUTC();
    dt = dt.minus({ days: 1 });
    dt = dt.endOf("day");

    //tomamos la fecha del último registro y comparamos la diferencia de días
    let fecha_ultimo = DateTime.fromISO(ultimo.fecha_ing);
    fecha_ultimo = fecha_ultimo.endOf("day");

    // let interval = Interval.fromDateTimes(fecha_ultimo, dt);

    let interval = Interval.fromDateTimes(fecha_ultimo, dt);

    if (interval.invalidReason === null && !isNaN(interval.difference())) {
      await db.table("fichajes").update(ultimo.id, {
        fecha_sal: fecha_ultimo.toString(),
        lat_sal: ultimo.lat_ing,
        lng_sal: ultimo.lng_ing,
      });
      localStorage.setItem("entrada", "entrada");
    }
  }

  const fichajes = await db
  .table("fichajes")
  .where("subido")
  .belowOrEqual(1)
  .toArray()
  .then(array => array.sort((a, b) => a.id - b.id));

    //.reverse()


    if (fichajes.length > 0) {

      console.log(fichajes);

      fichajes.forEach((element, index) => {
        if (element.subido === 1 && element.fecha_sal === null) {
          fichajes.splice(index, 1);
        }
      });

      try {
        if (fichajes.length > 0) {
          return await subir(fichajes)
        }
      } catch (error) {
        console.log(error);
      }
    }

};

export const subir = async (registros) => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("tokenUsuario"),
    },
  };

  if (window.navigator.onLine) {
    let response = await axios
      .post(
        "fichaje/marcar/total",
        {
          data: registros
        },
        headers
      )
      .then(async(response) => {

        await db.table("fichajes").clear();

        const data = response.data.data;

        const messageResult = response.data.message ?? "Fichaje registrado correctamente";
        const messageCode = response.data.code ?? "success";

        if (data && data.length > 0) {
          data.forEach(async element => {
            await db.table("fichajes").add(element);
          });

          const element = data[data.length -1];

          let textButton = "entrada";

          if (element.fecha_sal === null) {
            textButton = "salida";
          }
          
          localStorage.setItem("entrada", textButton);

          return [textButton, messageResult, messageCode];

        }else{
          localStorage.setItem("entrada", "entrada");
          return ["entrada", messageResult, messageCode];
        }
      })
      .catch((error) => {
        throw error;
      });

    return response;
  }

};
