import React, { useState, useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  AlertTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import Alert from "@mui/material/Alert";
import { db } from "../helpers/db";
import { DateTime } from "luxon";
import { subir } from "../helpers/cron";

const Alert2 = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Fichaje = () => {
  const navigate = useNavigate();

  let { lat, lon } = useParams();

  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [messageError, setMessageError] = useState("");
  const buttonRef = useRef(null);

  const errorAx = (error) => {
    try {
      if (
        error.response.data.message === undefined ||
        error.response.data.message === ""
      ) {
        throw Error("");
      }
      setMessageError(error.response.data.message);
    } catch (catcherror) {
      setMessageError(error.message);
    } finally {
      setOpenBackdrop(false);
      setOpenError(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSubmit = async () => {
    setOpenBackdrop(true);
    const ultim = await db
      .table("fichajes")
      .orderBy("id")
      .reverse()
      .limit(1)
      .first();
    const fecha = DateTime.now().toUTC().toString();
    // let fecha = DateTime.now().minus({ days: 1 }).toString();

    let message = "Fichaje registrado correctamente"
    let codeMessage = "success"

    if (ultim && ultim.fecha_sal === null) {

      await db.table("fichajes").update(ultim.id, {
        fecha_sal: fecha,
        lat_sal: lat,
        lng_sal: lon,
      });

      let ultimo = await db
      .table("fichajes")
      .where("id")
      .equals(ultim.id)
      .first();

      let textButton = "entrada";

      if (ultimo.fecha_sal === null) {
        textButton = "salida";
      }

      //verificamos si tiene internet
      if (window.navigator.onLine) {

        try {
          let arrayResponse = await subir([ultimo]);

          let momentTextButton = arrayResponse[0];
          codeMessage = arrayResponse[2];
          message = arrayResponse[1];

          if (
            typeof momentTextButton === typeof "" &&
            ["entrada", "salida"].includes(momentTextButton)
          ) {
            textButton = momentTextButton;
          }
        } catch (error) {
          if (error.code !== "ECONNABORTED"){
            errorAx(error);
            return;
          }          
        }
      }
      
      localStorage.setItem("entrada", textButton);

      //hay que marcar entrada
    } else {

      let ultimo = await db.table("fichajes").add({
        fecha_ing: fecha,
        lat_ing: lat,
        lng_ing: lon,
        subido: 0,
        fecha_sal: null,
        lat_sal: null,
        lng_sal: null,
      });

      ultimo = await db
      .table("fichajes")
      .where("id")
      .equals(ultimo)
      .first();

      let textButton = "entrada";

      if (ultimo.fecha_sal === null) {
        textButton = "salida";
      }

      //verificamos si tiene internet
      if (window.navigator.onLine) {
        try {
          let arrayResponse = await subir([ultimo]);

          let momentTextButton = arrayResponse[0];
          codeMessage = arrayResponse[2];
          message = arrayResponse[1];

          if (
            typeof momentTextButton === typeof "" &&
            ["entrada", "salida"].includes(momentTextButton)
          ) {
            textButton = momentTextButton;
          }
          
        } catch (error) {
          if (error.code !== "ECONNABORTED"){
            errorAx(error);
            return;
          }
        }
      }

      localStorage.setItem("entrada", textButton);

    }
    
    return navigate(`/?message_${codeMessage}=${message}`);
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenUsuario");

    if (token === undefined || token === "" || token === null) {
      return navigate("/login");
    }

    setOpenBackdrop(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' });
      // buttonRef.current.focus();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lon),
  };

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
      marginX={4}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {window.navigator.onLine ? (
        window.google === undefined ? (
          <LoadScript googleMapsApiKey="AIzaSyA5GV1fUQvk2V93M97f6qlRNByN50JEzqg">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={13}
            >
              <MarkerF position={center} />
            </GoogleMap>
          </LoadScript>
        ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            <MarkerF position={center} />
          </GoogleMap>
        )
      ) : (
        <Alert severity="info" style={{ width: "90%" }}>
          <AlertTitle>Esta es tu locación</AlertTitle>
          Latitud: <strong>{lat}</strong> <br />
          Longitud: <strong>{lon}</strong>
        </Alert>
      )}

      {/* AIzaSyA5GV1fUQvk2V93M97f6qlRNByN50JEzqg */}
      <Button
        variant="contained"
        color="primary"
        ref={buttonRef}
        mt={1}
        fullWidth
        sx={{ margin: 2, maxWidth: 500 }}
        onClick={handleSubmit}
        s
      >
        Guardar
      </Button>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert2 onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert2>
      </Snackbar>
    </Box>
  );
};

export default Fichaje;
